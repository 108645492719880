@import "../../../node_modules/bootstrap/scss/bootstrap.scss";
@import "./variables.scss";
@import "./fonts.scss";

.btn-primary {
    background-color: $primary-color;
    border: 1px solid $primary-color;
    border-radius: 6px;
    padding: 0.5rem 1rem;
    font-family: "$font-regular";
    &:hover,
    &:focus,
    &:disabled {
        background-color: $primary-color;
        border: 1px solid $primary-color;
        opacity: 0.8;
    }
}

.btn-secondary {
    border-radius: 6px;
    padding: 0.5rem 1rem;
    font-family: "$font-regular";
}

.btn-info, .btn-danger {
    border-radius: 6px;
    padding: 0.5rem 1rem;
    font-family: "$font-regular";
    color: $white;
    &:hover,
    &:focus,
    &:disabled {
    color: $white;
    }
}
