
.table-striped > tbody > tr:nth-of-type(odd) {
	--bs-table-accent-bg: #fff !important;
}


.table-striped > tbody > tr:nth-of-type(odd) {
	--bs-table-striped-bg: #f5f6fa !important;
}
.table {
	// --bs-table-hover-bg : #e4e0e0 !important;
	--bs-table-bg: #fff !important;
}

.associatebtn {
	width: 110px !important;
	color: #fff;
	border-radius: 17px;
	background: #6c757d;
	border: 0;
	margin: 10px;
  }

.statusbtn {
	width: 90px !important;
	color: #fff;
	border-radius: 17px;
	background: #6c757d;
	border: 0;
	margin: 10px;
  }