@import "../../assets/styles/variables.scss";
@import "../../assets/styles/fonts.scss";

.login-form {
  min-height: 100vh;
  padding: 4rem 0;
  background-color: $bg-color;
  .form-class {
    width: 30%;
    border: 1px solid $white;
    border-radius: 5px;
    padding: 2rem;
    box-shadow: 0px 2px 20px 2px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 2px 20px 2px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 2px 20px 2px rgba(0, 0, 0, 0.15);
    h2,
    p {
      color: $white;
      font-family: "$font-bold";
      margin-bottom: 1rem;
    }
    > form {
      div {
        text-align: left;
      }
      .form-label {
        color: $white;
        font-family: "$font-regular";
      }
      .form-control {
        font-family: "$font-regular";
      }
    }
    .button-div {
      margin-top: 1.5rem;
      a {
        color: $white;
        font-family: "$font-regular";
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
.fa-div {
  .otp-input {
    width: 3rem !important;
    height: 3rem;
    border-radius: 6px;
    border-color: $bg-color;
  }
  .otp-div {
    margin: 2rem 0;
    justify-content: space-evenly;
  }
  .otp-input-focus {
    box-shadow: 0 0 8px firebrick;
  }
}
