@import "../../assets/styles/variables.scss";
@import "../../assets/styles/fonts.scss";

.sidemenu {
    background-color: $bg-color;
    min-height: 100%;
    ul {
        padding: 0;
        li {
            list-style: none;
            text-align: left;
            a {
                padding: 1.5rem;
                color: $white;
                text-decoration: none;
                font-family: "$font-regular";
                display: block;
                &:hover,
                &.active {
                    background-color: #2b313d;
                    color: $primary-color;
                }
            }
        }
    }
}
