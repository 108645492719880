@import "../../assets/styles/variables.scss";
@import "../../assets/styles/fonts.scss";

header {
    background-color: $bg-color;
    padding: 0.5rem 1.5rem;
    h3 {
        font-size: 1.1rem;
        color: $white;
        font-family: "$font-bold";
    }
    .dropdown-menu-dark .dropdown-item:active {
        background: rgba(255, 255, 255, 0.15);
    }
}

.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus{
    box-shadow: none !important;
}

.btn-check:focus + .btn-secondary, .btn-secondary:focus{
    box-shadow: none !important;
}