@font-face {
    font-family: "$font-bold";
    src: url("../fonts/Helvetica-Bold.ttf");
    font-display: swap;
}

@font-face {
    font-family: "$font-regular";
    src: url("../fonts/Helvetica.ttf");
    font-display: swap;
}

@font-face {
    font-family: "$font-oblique";
    src: url("../fonts/Helvetica-Oblique.ttf");
    font-display: swap;
}
